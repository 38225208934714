import Flickity from "flickity";
import { initlazy } from "../images";

const states = {
    history: {
        title: null,
    },
};

export function initprojekt() {
    let routes = document.querySelectorAll("a.routing");
    if (routes) {
        routing();
    }

    buildProjekt();
}

function buildProjekt() {
    let gallery = document.querySelector(".gallery");
    if (gallery) {
        initGallery();
    }

    let more = document.getElementById("projekt-more");
    if (more) {
        projektMore();
    }

    let less = document.querySelector(".projekt-less");
    if (less) {
        projektLess();
    }
}

function initGallery() {
    let parent = document.querySelector(".gallery");
    let textel = document.getElementById("gallery-description");

    var gallery = new Flickity(parent, {
        accessibility: true,
        lazyLoad: true,
        wrapAround: true,
        pageDots: true,
        selectedAttraction: 0.2,
        friction: 0.8,
        prevNextButtons: true,
        arrowShape:
            "M26.67,0h8.33S8.67,50,8.67,50l26.33,50h-8.33S0,50,0,50L26.67,0Z",
    });

    gallery.on("change", function (index) {
        let cell = gallery.cells[index].element;
        let img = cell.querySelector("img");
        let alt = img.dataset.alt;

        if (alt != null) {
            textel.innerHTML = alt;
        } else {
            textel.innerHTML = "&nbsp;";
        }
    });
}

function projektMore() {
    let more = document.getElementById("projekt-more");
    let text = document.getElementById("projekt-text");
    more.addEventListener("click", () => {
        text.scrollTo({
            top: 0,
            behavior: "instant",
        });
        text.classList.remove("inactive");
    });
}

function projektLess() {
    let less = document.querySelectorAll(".projekt-less");
    let text = document.getElementById("projekt-text");

    less.forEach((l) => {
        l.addEventListener("click", () => {
            text.classList.add("inactive");
        });
    });
}

function routing() {
    let routes = document.querySelectorAll("a.routing");

    routes.forEach((route) => {
        route.addEventListener("click", (e) => {
            if (!document.body.hasAttribute("fetch")) {
                //DISABLE LINK
                e.preventDefault();

                let el = e.target.closest("a");
                if (!el) {
                    el = e.target;
                }
                let url = el.dataset.href;

                if (url) {
                    // LOAD CONTENT
                    const fetchContent = async () => {
                        try {
                            const response = await fetch(url);
                            const { html, title, slug, href } =
                                await response.json();
                            buildContent(html, title, slug, href);
                        } catch (error) {
                            console.log("Fetch error: ", error);
                        }
                    };
                    fetchContent();
                }
            }
        });
    });
}

function buildContent(html, title, slug, href) {
    if (history.scrollRestoration) {
        history.scrollRestoration = "auto";
    }
    // GET VARIABLES
    let time = parseInt(
        getComputedStyle(document.body).getPropertyValue("--time-l")
    );

    // SET ATTRIBUTES & CONTENT
    document.body.setAttribute("fetch", "");
    document.body.insertAdjacentHTML("beforeend", html);

    buildProjekt();
    initlazy();

    document.body.setAttribute("transition", "");
    setTimeout(() => {
        document.body.setAttribute("fetched", "");
        document.body.removeAttribute("transition");

        let close = document.querySelector("nav.close");
        close.addEventListener("click", destroyContent);
    }, time);

    // SET HISTORY AT HALF TIME

    setTimeout(() => {
        states.history.title = document.title;

        history.pushState({ page: "projekt" }, title, href);
        document.title = title;
    }, time * 0.3);
}

function destroyContent(e) {
    if (history.scrollRestoration) {
        history.scrollRestoration = "manual";
    }

    //DISABLE LINK
    e.preventDefault();

    // GET VARIABLES
    let time = parseInt(
        getComputedStyle(document.body).getPropertyValue("--time-l")
    );

    // SET ATTRIBUTES & CONTENT
    document.body.removeAttribute("fetched");

    setTimeout(() => {
        document.body.removeAttribute("fetch");

        let el = document.getElementById("projekt");
        el.remove();
    }, time);

    // SET HISTORY AT HALF TIME

    setTimeout(() => {
        history.back();
        document.title = states.history.title;
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "instant",
            });
        }, 5);
    }, time * 0.3);
}
