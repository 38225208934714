export function initmenu() {
    let open = document.getElementById("menu-open");
    let menu = document.getElementById("main");
    if (open && menu) {
        menuOpen();
        menuClose();
    }
}

function menuOpen() {
    let open = document.getElementById("menu-open");
    let menu = document.getElementById("main");

    open.addEventListener("click", () => {
        menu.classList.remove('inactive');
    });
}

function menuClose(){
    let menu = document.getElementById("main");
    menu.addEventListener('click', (e) => {
        if(e.target !== e.currentTarget) return;
        menu.classList.add('inactive');
    })

    let list = menu.querySelector('li.gap');
    list.addEventListener('click', (e) => {
        if(e.target !== e.currentTarget) return;
        console.log("okay");
        menu.classList.add('inactive');
    })

}